.formContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.button {
  color: #5a32bf;
  border: 1px solid #5a32bf;
  margin-top: 10px;
}

p {
  font-size: 10px;
  text-align: center;
  width: 70%;
}
