.courseInfoContainer {
  width: 35%;
  height: auto;
  margin: 10px 0;
  padding: 30px 2vw;
  border-radius: 12px;
  background: #f6f9fa;
}

.courseTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #919da1;
  margin-bottom: 10px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
}

.infoTitle {
  color: #fc4e4e;
}

.infoDetail {
  color: #000000;
}

@media (max-width: 810px) {
  .courseInfoContainer {
    width: 85%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
