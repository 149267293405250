.screenshotContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #efebf9;
  padding: 0 0 20px 0;
  overflow-y: auto;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  padding: 10px 10px;
}

.eventsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 0.25vw;
  flex: 1;
}

.classContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  width: 100%;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  margin: 5px 0;
  padding: 5px;
  border-radius: 8px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  align-items: center;
  background-color: #5a32bf;
  padding: 0.5rem 10%;
}

.title {
  color: white;
}

.button {
  width: auto;
  height: 25px;
  padding: 5px 2vw;
  border-radius: 20px;
  border: 1px solid white;
  text-transform: unset !important;
  color: white;
  margin: 1rem 0;
}

.invisibleButton {
  width: auto;
  height: 25px;
  padding: 5px 2vw;
  color: #5a32bf;
  margin: 1rem 0;
}

@media only screen and (max-width: 680px) {
  .titleContainer {
    font-size: 2vw;
  }
  .classContainer {
    font-size: 2vw;
  }
  .headerContainer {
    padding: 0.5rem 10px;
  }
}
