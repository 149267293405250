.dayContainer {
  flex-grow: 10;
  position: relative;
}

.TUdayContainer {
  flex-basis: 0px;
  flex-grow: 10;
  position: relative;
}

.tuButton {
  color: #a0c3d1;
  text-transform: unset !important;
  margin: 5px;
  display: flex;
  overflow-wrap: break-word;
  flex-direction: column;
  white-space: normal;
  color: white;
  background-color: #87A5B5;
  height: auto;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 8px;
  text-align: center;
}

.tuButtonShrink {
  color: #a0c3d1;
  text-transform: unset !important;
  margin: 5px;
  display: flex;
  overflow-wrap: break-word;
  flex-direction: column;
  white-space: normal;
  color: white;
  background-color: #87A5B5;
  height: auto;
  border-radius: 5px;
  font-size: 13px;
  padding: 15px 8px;
  text-align: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.addAllButton {
  text-transform: unset !important;
  width: 50%;
  color: #a1c97d;
}

.removeAllButton {
  text-transform: unset !important;
  width: 50%;
}

.timeSlotTitle {
  height: 40px;
  min-width: 80px;
  text-align: center;
  color: #70757a;
}

.timeSlot {
  height: 80px;
  border: 1px solid #70757a;
  position: relative;
  z-index: -5;
}

.unStatedTimeColumn {
  flex-direction: column;
  height: 1040px;
  border: 1px solid #70757a;
  position: relative;
  z-index: -5;
}

.timeSlotOverlay {
  height: 40px;
  border: 1px solid #70757a;
}
